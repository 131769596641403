import React from 'react';
import ReactPlayer from 'react-player';
import ReactGA from 'react-ga';

import { ReactComponent as Logo } from './logo.svg';
import { ReactComponent as TimeAndPlace } from './time-and-place.svg';
import { ReactComponent as BookAndLyricsBy } from './book.svg';
import { ReactComponent as MusicBy } from './music.svg';

import './App.css';

const App: React.FC = () => {

  let videoProgress = 0;

  const onVideoPlay = () => {
    ReactGA.event({
      category: 'Video',
      action: 'Play',
    });
  };

  const onVideoEnded = () => {
    ReactGA.event({
      category: 'Video',
      action: 'Ended',
    });
  };

  const onVideoProgress = (state: any) => {
    console.log('onVideoProgress', state);
    // if(state.playedSeconds > videoProgress) {
    ReactGA.event({
      category: 'Video',
      action: 'Progress',
      value: state.playedSeconds || 0
    });
    // }

  };

  const onVideoPause = () => {
    ReactGA.event({
      category: 'Video',
      action: 'Paused',
    });
  };

  return (
    <div className="App">
      <Logo className="App-logo svg-logo"></Logo>
      <header>
        <div>
          <BookAndLyricsBy className="App-byline svg-logo" />
          <br />
          Mike Ross</div>
        <div>
          <MusicBy className="App-byline svg-logo" />
          <br />
          Richard Plotkin</div>
      </header>

      <TimeAndPlace className="App-time-and-place svg-logo"></TimeAndPlace>
      <p>A West Village apartment, 2019 and 1974.</p>
      <ReactPlayer
        height="35vw"
        className="player"
        url='https://vimeo.com/345155801'
        playing={false}
        onPlay={onVideoPlay}
        onEnded={onVideoEnded}
        onProgress={onVideoProgress}
        onPause={onVideoPause}
      />
      {/*<iframe className="player" src="https://player.vimeo.com/video/345155801" width="640" height="360" frameBorder="0"*/}
      {/*        allow="autoplay; fullscreen" allowFullScreen></iframe>*/}

      <h3>About</h3>
      <p>
        The BMI Lehman Engel Musical Theatre Workshop "10-minute musical" is a short work written by
        composer/lyricist teams participating in the first year of the program. We (Mike and Richard) were paired by the
        moderators. What you see here is the very first presentation of the material we developed.
        There was no feedback that shaped what you see &ndash; we did not know what would or would not work.
        We wrote for about four weeks, and had a few short rehearsals the week before the presentation.
        Going forward, we look forward to making this show the best it can be. Stay tuned for more!</p>
    </div>
  );
};

export default App;
